import React from 'react';
import Section from 'components/Section';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';

function Footer(props) {
    const router = useRouter();
    const current = router.pathname;
    let isRoot = current === '/';
    const isSharedPage =
        current === '/extension-installed' ||
        current === '/extension-uninstalled';

    if (isSharedPage) {
        return null;
    }

    return (
        <section
            className="footer"
            style={{
                backgroundImage:
                    'linear-gradient(180deg, #262A2D 0%, #262A2D 100%)'
            }}
        >
            <div className="FooterComponent__container container">
                <div className="brand left">
                    <Link href="/">
                        <img
                            src="/tabExtend_logo_footer.png"
                            alt="Logo"
                            style={{ height: '48px' }}
                            width="204"
                            height="48px"
                        ></img>
                    </Link>
                </div>
                <div
                    className="social right"
                    style={{ color: '#FFF', fontWeight: '500' }}
                >
                    <div className="columns is-mobile is-multiline">
                        <div className="column is-12-mobile is-narrow-tablet">
                            <div className="is-flex-mobile is-flex-direction-column is-align-items-center-mobile is-align-items-end-tablet">
                                {!isRoot && <Link href="/" className="mb-4 ml-4">About</Link>}
                                <Link href="/guide/getting-started" className="mb-4 ml-4">Guide</Link>
                                <Link href="/pricing" className="mb-4 ml-4">Pricing</Link>
                                <Link href="/updates" className="mb-4 ml-4">Updates</Link>
                                {/* <Link href="/blog" className="mb-4 ml-4">Blog</Link> */}
                                <Link href="https://tabextend.getrewardful.com/" className="mb-4 ml-4">
                                    Affiliates
                                </Link>
                                <a href="mailto:info@tabextend.com" className="mb-4 ml-4">Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="links right" style={{ color: '#FFF' }}></div>
                <div
                    className="copyright left"
                    style={{ color: '#FFF', opacity: '0.6', fontSize: '14px' }}
                >
                    {props.copyright}
                    <a
                        style={{
                            color: '#FFF',
                            fontSize: '14px',
                            marginLeft: '12px'
                        }}
                        href="https://www.tabextend.com/privacypolicy"
                        alt="Privacy policy"
                    >
                        Privacy policy
                    </a>
                </div>

                <div className="socialRow">
                    <a
                        href="https://www.youtube.com/channel/UCo4OUtyAHm6j2kONKz5O1WA"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="tabextend youtube channel - new window"
                    >
                        <span
                            className="icon"
                            style={{ color: '#FFF', marginRight: '24px' }}
                        >
                            <i
                                className="fab fa-youtube"
                                style={{ fontdisplay: 'swap' }}
                            ></i>
                        </span>
                    </a>
                    <a
                        href="https://fb.me/tabextend"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="tabextend facebook - new window"
                    >
                        <span
                            className="icon"
                            style={{ color: '#FFF', marginRight: '24px' }}
                        >
                            <i
                                className="fab fa-facebook"
                                style={{ fontdisplay: 'swap' }}
                            ></i>
                        </span>
                    </a>
                    <a
                        href="https://twitter.com/tabextend"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="tabextend twitter - new window"
                    >
                        <span className="icon" style={{ color: '#FFF' }}>
                            <i
                                className="fab fa-twitter"
                                style={{ fontdisplay: 'swap' }}
                            ></i>
                        </span>
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Footer;
